import { useEffect, useState } from 'react';
import { authenticatedFetch } from './helpers/fetch';
import { SignedIn, useUser, useAuth } from '@clerk/clerk-react';
import { Heading, Text, TextField } from '@radix-ui/themes';
import SelectElement from './components/SelectElement';
import SignedOutLink from './components/SignedOut';
import Breadcrumb from './components/Breadcrumb';
import AttendanceWeek from './components/AttendanceWeek';
import AttendanceMonth from './components/AttendanceMonth';
import Loading from 'react-fullscreen-loading';
import { format, subWeeks, addDays, addWeeks, subMonths, addMonths } from 'date-fns';
import { thisWeeksDates, thisMonthsDates, nameSearch } from './helpers/data';
import './styles/Customers.css';
import './styles/Attendance.css';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons';

function Attendance() {
    const { user } = useUser();
    const { getToken, isLoaded, isSignedIn } = useAuth();

    const links = [{ url: '/attendance', text: 'Customer attendance' }];

    const [customerData, setCustomerData] = useState({ data: [], isLoading: false });
    const [serviceData, setServiceData] = useState({ data: [], isLoading: false });
    const [dateStatusData, setDateStatusData] = useState({ data: [], isLoading: false });
    const [usualDates, setUsualDates] = useState({ data: [], isLoading: false });
    const [attendanceData, setAttendanceData] = useState({ data: [], isLoading: false });

    const [customers, setCustomers] = useState([]);

    const [keywords, setKeywords] = useState(localStorage.getItem('search') || '');
    const [status, setStatus] = useState(localStorage.getItem('status') || 'All');
    const [service, setService] = useState(localStorage.getItem('service') || 'All');
    const [view, setView] = useState(localStorage.getItem('view') || 'Week');
    const [services, setServices] = useState([]);
    const [thisWeek, setThisWeek] = useState(thisWeeksDates(new Date()));
    const [thisMonths, setThisMonth] = useState(thisMonthsDates(new Date()));
    const [customerDates, setCustomerDates] = useState([]);
    const [attendanceDates, setAttendanceDates] = useState([]);

    const load = async () => {
        if (isLoaded && isSignedIn && status?.length > 0 && service?.length > 0) {
            setCustomerData({ data: [], isLoading: true });
            authenticatedFetch(
                `${
                    window.location.origin
                }/api/data/customer?status=${status.toLowerCase()}&service=${service.toLowerCase()}`,
                await getToken()
            )
                .then((res) => res.json())
                .then((data) => {
                    setCustomerData({ data: data, isLoading: false });
                });
        }
    };

    useEffect(() => {
        load();
    }, [isLoaded, isSignedIn, status, service, view]);

    useEffect(() => {
        if (!customerData.isLoading) {
            if (keywords.length > 0) {
                // filter results
                setCustomers(nameSearch(keywords, customerData.data));
            } else {
                setCustomers([...customerData.data]);
            }
        }
    }, [keywords, customerData]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setServiceData({ data: [], isLoading: true });
                authenticatedFetch(
                    `${window.location.origin}/api/data/customer/0/services`,
                    await getToken()
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setServiceData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setDateStatusData({ data: [], isLoading: true });
                authenticatedFetch(
                    `${window.location.origin}/api/lookup/datestatus`,
                    await getToken()
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setDateStatusData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setUsualDates({ data: [], isLoading: true });
                authenticatedFetch(
                    `${window.location.origin}/api/data/customer/0/dates`,
                    await getToken()
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setUsualDates({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [service, view, isLoaded, isSignedIn]);

    useEffect(() => {
        const load = async () => {
            if (isLoaded && isSignedIn) {
                setAttendanceData({ data: [], isLoading: true });
                authenticatedFetch(
                    `${window.location.origin}/api/data/customer/0/attendance`,
                    await getToken()
                )
                    .then((res) => res.json())
                    .then((data) => {
                        setAttendanceData({ data: data, isLoading: false });
                    });
            }
        };
        load();
    }, [isLoaded, isSignedIn, service]);

    useEffect(() => {
        setServices(
            ['Please select...'].concat(
                [...new Set(serviceData?.data?.map((record) => record.name) ?? [])].sort()
            )
        );
    }, [serviceData?.data]);

    useEffect(() => {
        if (service && usualDates?.data?.length > 0) {
            const dates = usualDates?.data
                .filter((date) => date.name === service)
                .map((date) => {
                    return {
                        customer_id: date.customer_id,
                        date: format(date.date, 'do MMMM yyyy'),
                        funding_name: date.funding,
                        funding_id: date.funding_id,
                    };
                });
            setCustomerDates(dates);
        }
    }, [service, usualDates?.data]);

    useEffect(() => {
        if (service && attendanceData?.data?.length > 0) {
            const dates = attendanceData?.data
                .filter((date) => date.name === service)
                .map((date) => {
                    return {
                        customer_id: date.customer_id,
                        date: format(date.date, 'do MMMM yyyy'),
                        datestatus_id: date.datestatus_id,
                        datestatus_name: date.datestatus_name,
                    };
                });
            setAttendanceDates(dates);
        }
    }, [service, attendanceData?.data]);

    const onSearchChange = (val) => {
        setKeywords(val);
        localStorage.setItem('search', val);
    };

    const onStatusChange = (val) => {
        setStatus(val);
        localStorage.setItem('status', val);
    };

    const onServiceChange = (val) => {
        setService(val);
        localStorage.setItem('service', val);
    };

    const onViewChange = (val) => {
        setView(val);
        localStorage.setItem('view', val);
    };

    const onWeekDateChange = (direction) => {
        const dates = thisWeeksDates(
            direction === 'left' ? subWeeks(thisWeek[0], 1) : addWeeks(thisWeek[0], 1)
        );
        setThisWeek(dates);
    };

    const firstOfMonth = (date) => {
        if (date.getDate() === 1) {
            return date;
        }

        while (date.getDate() !== 1) {
            date = addDays(date, 1);
        }

        return date;
    };

    const onMonthDateChange = (direction) => {
        const dates = thisMonthsDates(
            direction === 'left'
                ? firstOfMonth(subMonths(thisMonths[0], 1))
                : firstOfMonth(addMonths(thisMonths[0], 1))
        );
        setThisMonth(dates);
    };

    const onDateClick = async (customerId, date, statusId, statusName) => {
        console.log('statusId', statusId);
        console.log('statusName', statusName);

        // If colour should be removed entirely don't permission rest of function
        if (statusName === 'Clear Colour') {
            let clonedCustomerDates = structuredClone(customerDates);
            const index = clonedCustomerDates.findIndex(
                (i) => i.customer_id === customerId && i.date === format(date, 'do MMMM yyyy')
            );
            if (index !== -1) {
                clonedCustomerDates.splice(index, 1);
            }

            setCustomerDates(clonedCustomerDates);

            await authenticatedFetch(
                `${window.location.origin}/api/data/customer/${customerId}/servicefunding`,
                await getToken(),
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        date: format(date, 'yyyy-MM-dd'),
                        service_id: serviceData?.data.find((ser) => ser.name === service)?.id,
                    }),
                }
            );

            return;
        }

        // Attended Private, Attended Council
        const funding = statusName.indexOf(' ') > -1 ? statusName.split(' ').reverse()[0] : null;
        const hasFunding = funding === 'Private' || funding === 'Council';

        const data = {
            date: format(date, 'yyyy-MM-dd'),
            customerId,
            serviceId: serviceData?.data.find((ser) => ser.name === service)?.id,
            userId: user?.id,
            dateStatusId: statusId,
        };

        // Clone existing data and remove record for that day (if exists)
        let cloned = [...attendanceDates];
        const index = cloned.findIndex(
            (i) => i.customer_id === customerId && i.date === format(date, 'do MMMM yyyy')
        );
        if (index !== -1) {
            cloned.splice(index, 1);
        }

        // Add new record to data
        if (statusName !== 'Clear') {
            const obj = {
                customer_id: customerId,
                date: format(date, 'do MMMM yyyy'),
                datestatus_id: statusId,
                datestatus_name: hasFunding
                    ? statusName.replace(' Private', '').replace(' Council', '')
                    : statusName,
            };

            if (hasFunding) {
                obj.funding = statusName.split(' ')[1];
                obj.hasCustomerDate = true;
            }

            cloned = cloned.concat([obj]);
        }

        setAttendanceDates(cloned);

        await authenticatedFetch(
            `${window.location.origin}/api/data/customer/${customerId}/attendance`,
            await getToken(),
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }
        ).then((res) => res.json());

        // if funding is supplied i.e. adult day care
        if (hasFunding) {
            // Clone existing data and remove record for that day (if exists)
            let clonedCustomerDates = structuredClone(customerDates);
            const index = clonedCustomerDates.findIndex(
                (i) => i.customer_id === customerId && i.date === format(date, 'do MMMM yyyy')
            );
            if (index !== -1) {
                clonedCustomerDates.splice(index, 1);
            }

            // Save Funding Data
            const fundingData = {
                date: format(date, 'yyyy-MM-dd'),
                service_id: serviceData?.data.find((ser) => ser.name === service)?.id,
                funding_id: funding === 'Private' ? 1 : 2,
            };

            await authenticatedFetch(
                `${window.location.origin}/api/data/customer/${customerId}/servicefunding`,
                await getToken(),
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(fundingData),
                }
            ).then((res) => res.json());

            // Add to local customers dates
            clonedCustomerDates.push({
                customer_id: customerId,
                date: format(date, 'do MMMM yyyy'),
                funding_name: funding,
                funding_id: funding === 'Private' ? 1 : 2,
            });

            setCustomerDates(clonedCustomerDates);
        }
    };

    if (
        customerData.isLoading ||
        dateStatusData.isLoading ||
        serviceData.isLoading ||
        usualDates.isLoading ||
        attendanceData.isLoading
    )
        return <Loading loading loaderColor="#3498db" />;

    return (
        <>
            <SignedOutLink />
            <SignedIn>
                <div className="page-header">
                    <Heading>Customer attendance</Heading>

                    <div>
                        <Text className="SubLabel">Search</Text>
                        <TextField.Root placeholder="Search by name...">
                            <TextField.Slot>
                                <MagnifyingGlassIcon height="16" width="16" />
                            </TextField.Slot>
                            <TextField.Input
                                name="search"
                                maxLength={128}
                                onChange={(e) => onSearchChange(e.target.value)}
                                value={keywords}
                            />
                        </TextField.Root>
                    </div>

                    <div>
                        <Text className="SubLabel">Status</Text>
                        <SelectElement
                            name="status"
                            label="Status"
                            placeholder="Status"
                            data={['All', 'Active', 'Inactive']}
                            onChange={onStatusChange}
                            value={status}
                        />
                    </div>

                    <div>
                        <Text className="SubLabel">Service</Text>
                        <SelectElement
                            name="service"
                            label="Service"
                            placeholder="Service"
                            data={services}
                            onChange={onServiceChange}
                            value={service}
                        />
                    </div>

                    <div>
                        <Text className="SubLabel">View Calendar By...</Text>
                        <SelectElement
                            name="view"
                            label="View"
                            placeholder="View"
                            data={['Week', 'Month']}
                            onChange={onViewChange}
                            value={view}
                        />
                    </div>
                </div>

                <Breadcrumb links={links} />

                {customers?.length === 0 &&
                    service?.length > 0 &&
                    service !== 'Please select...' && (
                        <Text style={{ margin: '40px 0 0 20px' }}>
                            No customers currently available
                        </Text>
                    )}

                {service?.length > 0 && service === 'Please select...' && (
                    <Text style={{ margin: '40px 0 0 20px' }}>
                        Please select a service from the toolbar above
                    </Text>
                )}

                {customers?.length > 0 &&
                    service?.length > 0 &&
                    service !== 'Please select...' &&
                    view === 'Week' && (
                        <AttendanceWeek
                            onDateClick={onDateClick}
                            onDateChange={onWeekDateChange}
                            thisWeek={thisWeek}
                            customers={customers}
                            attendanceDates={attendanceDates}
                            customerDates={customerDates}
                            dateStatusData={dateStatusData}
                            service={service}
                        />
                    )}

                {customers?.length > 0 &&
                    service?.length > 0 &&
                    service !== 'Please select...' &&
                    view === 'Month' && (
                        <AttendanceMonth
                            onDateClick={onDateClick}
                            onDateChange={onMonthDateChange}
                            thisMonths={thisMonths}
                            customers={customers}
                            attendanceDates={attendanceDates}
                            customerDates={customerDates}
                            dateStatusData={dateStatusData}
                            service={service}
                        />
                    )}
            </SignedIn>
        </>
    );
}

export default Attendance;
